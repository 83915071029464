function getBimodalContent(
  productionDateSelected,
  selecteddatatype,
  selecteddataset,
  a,
  b,
  c,
  cropNameSelected,
  rainfallScore,
  TemperatureCropNameSelected,
  temperatureScore,
  season,
  rainfallRankingData,
  temperatureRankingData
) {
  const today = new Date().toLocaleDateString(); // Get today's date in a readable format

  const suitabilityRows = rainfallRankingData
    .map(
      (data) => `
    <tr>
      <td style="border: 1px solid #ddd; padding: 8px; background-color: rgba(217, 217, 217, 0.30);">${data.cropName}</td>
      <td style="border: 1px solid #ddd; padding: 8px; background-color: rgba(217, 217, 217, 0.30);">${data.varietyName}</td>
      <td style="border: 1px solid #ddd; padding: 8px; background-color: rgba(217, 217, 217, 0.30);">${data.score}/100</td>
    </tr>
  `
    )
    .join("");
  const temperatureRows = temperatureRankingData
    .map(
      (data) => `
    <tr>
      <td style="border: 1px solid #ddd; padding: 8px; background-color: rgba(217, 217, 217, 0.30);">${data.cropName}</td>
      <td style="border: 1px solid #ddd; padding: 8px; background-color: rgba(217, 217, 217, 0.30);">${data.varietyName}</td>
      <td style="border: 1px solid #ddd; padding: 8px; background-color: rgba(217, 217, 217, 0.30);">${data.score}/100</td>
    </tr>
  `
    )
    .join("");
  return `
    <div style="padding: 25px; font-size: 12px;">
      <!-- Report generation and production date -->
      <div style="display: flex; justify-content: space-between; align-items: center; margin-bottom: 15px;">
        <div style="font-size: 12px;">Report Generation Date: ${today}</div>
        <div style="font-size: 12px;">Production Start Date: ${productionDateSelected}</div>
      </div>

      <!-- Heading -->
      <div style="text-align: center; margin: 0;">
      <h2 style="display: inline-flex; justify-content: center; align-items: center; margin: 0;">
        <span style="background-color: #F5821F; color: white; font-weight: bold; padding: 10px 15px; border-top-left-radius: 5px; border-bottom-left-radius: 5px;">
          TARA
        </span>
        <span style="background-color: lightgrey; color: black;font-weight:500; padding: 10px 15px; border-top-right-radius: 5px; border-bottom-right-radius: 5px;">
          CROP PRODUCTIVITY REPORT
        </span>
      </h2>
    </div>
    
    
      <!-- First Fieldset: LOCATION -->
     
     
      <fieldset style="margin-top: 35px; border: 1px solid #ddd; padding: 15px; border-radius: 10px; position: relative;">
        <legend style="position: absolute; top: -10px; left: 15px; background-color: white; padding: 0 10px; color: grey; font-weight: bold;">LOCATION</legend>
        <table style="width: 100%; border-collapse: collapse; margin-top: 10px; border-radius: 10px; overflow: hidden;">
        <thead>
          <tr>
            <th style="border: 1px solid #ddd; padding: 8px; text-align: left; background-color: lightgrey;">Country</th>
            <th style="border: 1px solid #ddd; padding: 8px; text-align: left; background-color: lightgrey;">County</th>
            <th style="border: 1px solid #ddd; padding: 8px; text-align: left; background-color: lightgrey;">Sub County</th>
            <th style="border: 1px solid #ddd; padding: 8px; text-align: left; background-color: lightgrey;">Ward</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style="border: 1px solid #ddd; padding: 8px; background-color: rgba(217, 217, 217, 0.30);">Kenya</td>
            <td style="border: 1px solid #ddd; padding: 8px; background-color: rgba(217, 217, 217, 0.30);">${a}</td>
            <td style="border: 1px solid #ddd; padding: 8px; background-color: rgba(217, 217, 217, 0.30);">${b}</td>
            <td style="border: 1px solid #ddd; padding: 8px; background-color: rgba(217, 217, 217, 0.30);">${c}</td>
          </tr>
        </tbody>
      </table>
      </fieldset>
 
      <fieldset style="margin-top: 20px; border: 1px solid #ddd; padding: 15px; border-radius: 10px; position: relative;">
      <legend style="position: absolute; top: -10px; left: 15px; background-color: white; padding: 0 10px; color: grey; font-weight: bold;">BEST RANKED CROP</legend>
      <table style="width: 100%; border-collapse: collapse; margin-top: 10px; border-radius: 10px; overflow: hidden;">
      <thead>
        <tr>
          <th style="width: 50%; border: 1px solid #ddd; padding: 8px; text-align: left; background-color: lightgrey;">Crop (Rainfall)</th>
          <th style="width: 50%; border: 1px solid #ddd; padding: 8px; text-align: left; background-color: lightgrey;">Productivity Score</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td style="width: 50%; border: 1px solid #ddd; padding: 8px; background-color: rgba(217, 217, 217, 0.30);">${cropNameSelected}</td>
          <td style="width: 50%; border: 1px solid #ddd; padding: 8px; background-color: rgba(217, 217, 217, 0.30);">${rainfallScore}</td>
        </tr>
      </tbody>
    </table>
    
    <table style="width: 100%; border-collapse: collapse; margin-top: 10px; border-radius: 10px; overflow: hidden;">
      <thead>
        <tr>
          <th style="width: 50%; border: 1px solid #ddd; padding: 8px; text-align: left; background-color: lightgrey;">Crop (Temperature)</th>
          <th style="width: 50%; border: 1px solid #ddd; padding: 8px; text-align: left; background-color: lightgrey;">Productivity Score</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td style="width: 50%; border: 1px solid #ddd; padding: 8px; background-color: rgba(217, 217, 217, 0.30);">${TemperatureCropNameSelected}</td>
          <td style="width: 50%; border: 1px solid #ddd; padding: 8px; background-color: rgba(217, 217, 217, 0.30);">${temperatureScore}</td>
        </tr>
      </tbody>
    </table>
    
      <!-- Vertical Bars with Labels -->
      <div style="display: flex; justify-content: space-between; align-items: flex-end; margin-top: 20px; height: 150px; text-align: center;">
  <div style="width: 20%; display: flex; flex-direction: column; align-items: center;">
    <span style="font-size: 12px; color: black; margin-bottom: 5px;">High Risk</span>
    <div style="width: 100%; height: 40px; background-color: #FF0000; border-radius: 8px;"></div>
    <span style="font-size: 12px; color: black; margin-top: 5px;">0-40</span>
  </div>

  <div style="width: 20%; display: flex; flex-direction: column; align-items: center;">
    <span style="font-size: 12px; color: black; margin-bottom: 5px;">Moderate Risk</span>
    <div style="width: 100%; height: 70px; background-color: #FEB703; border-radius: 8px;"></div>
    <span style="font-size: 12px; color: black; margin-top: 5px;">41-65</span>
  </div>

  <div style="width: 20%; display: flex; flex-direction: column; align-items: center;">
    <span style="font-size: 12px; color: black; margin-bottom: 5px;">Low Risk</span>
    <div style="width: 100%; height: 100px; background-color: #02B801; border-radius: 8px;"></div>
    <span style="font-size: 12px; color: black; margin-top: 5px;">66-90</span>
  </div>

  <div style="width: 20%; display: flex; flex-direction: column; align-items: center;">
    <span style="font-size: 12px; color: black; margin-bottom: 5px;">Minimal Risk</span>
    <div style="width: 100%; height: 120px; background-color: #026B01; border-radius: 8px;"></div>
    <span style="font-size: 12px; color: black; margin-top: 5px;">91-100</span>
  </div>
</div>

    </fieldset>
      <fieldset style="margin-top: 20px; border: 1px solid #ddd; padding: 15px; border-radius: 10px; position: relative;">
      <legend style="position: absolute; top: -10px; left: 15px; background-color: white; padding: 0 10px; color: grey; font-weight: bold;">DATASETS</legend>
      <table style="width: 100%; border-collapse: collapse; margin-top: 10px; border-radius: 10px; overflow: hidden;">
        <thead>
          <tr>
            <th style="border: 1px solid #ddd; padding: 8px; text-align: left; background-color: lightgrey;">Dataset Type</th>
            <th style="border: 1px solid #ddd; padding: 8px; text-align: left; background-color: lightgrey;">Dataset Name</th>
            <th style="border: 1px solid #ddd; padding: 8px; text-align: left; background-color: lightgrey;">Model Type</th> 
            <th style="border: 1px solid #ddd; padding: 8px; text-align: left; background-color: lightgrey;">Season</th> 
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style="border: 1px solid #ddd; padding: 8px; background-color: rgba(217, 217, 217, 0.30);">${selecteddatatype}</td>
            <td style="border: 1px solid #ddd; padding: 8px; background-color: rgba(217, 217, 217, 0.30);">${selecteddataset}</td>
            <td style="border: 1px solid #ddd; padding: 8px; background-color: rgba(217, 217, 217, 0.30);">Simple</td>
            <td style="border: 1px solid #ddd; padding: 8px; background-color: rgba(217, 217, 217, 0.30);">${season}</td>

          </tr>
          <tr>
          <td style="border: 1px solid #ddd; padding: 8px; background-color: rgba(217, 217, 217, 0.30);">Temperature</td>
          <td style="border: 1px solid #ddd; padding: 8px; background-color: rgba(217, 217, 217, 0.30);">ERA5</td>
          <td style="border: 1px solid #ddd; padding: 8px; background-color: rgba(217, 217, 217, 0.30);">Simple</td>
          <td style="border: 1px solid #ddd; padding: 8px; background-color: rgba(217, 217, 217, 0.30);">${season}</td>

        </tr>
        </tbody>
      </table>
    </fieldset>

      <!-- Third Fieldset: BEST RANKED CROP -->
     
    
    <fieldset style="margin-top: 20px; border: 1px solid #ddd; padding: 15px; border-radius: 10px; position: relative;">
    <legend style="position: absolute; top: -10px; left: 15px; background-color: white; padding: 0 10px; color: grey; font-weight: bold;">RECOMMENDATION</legend>
    <table style="width: 100%; border-collapse: collapse; margin-top: 10px; border-radius: 10px; overflow: hidden;">
      <thead>
        <tr>
          <th style="text-align: left; font-size: 14px; font-weight: bold; border: 1px solid #ddd; padding: 8px; background-color: lightgrey;">
            CROP: ${cropNameSelected} | SCORE: ${rainfallScore}%
          </th>
        </tr>
      </thead>
      <tbody>
      <tr>
      <td style="border: 1px solid #ddd; padding: 8px; background-color: rgba(217, 217, 217, 0.30); font-size: 12px; color: #555;">
        <span style="display: inline-block; width: 28px; height: 28px; background-color: #87CEFA; color: white; border-radius: 50%; text-align: center; line-height: 28px; font-size: 16px; font-weight: bold; margin-right: 10px;">i</span>
        <span style="display: inline-block; vertical-align: top; max-width: 90%; word-wrap: break-word;">
          ${(() => {
            if (rainfallScore >= 0 && rainfallScore <= 40) {
              return `A productivity score of <b>${rainfallScore}%</b> for ${cropNameSelected} suggests an <b>extremely high production risk</b> and is not advisable for loan disbursement.`;
            } else if (rainfallScore >= 41 && rainfallScore <= 65) {
              return `A productivity score of <b>${rainfallScore}%</b> for ${cropNameSelected} suggests a <b>high production risk</b>. A loan should be disbursed with mandatory insurance coverage.`;
            } else if (rainfallScore >= 66 && rainfallScore <= 90) {
              return `A productivity score of <b>${rainfallScore}%</b> for ${cropNameSelected} suggests a <b>moderate production risk</b>. A loan can be disbursed with optional insurance coverage.`;
            } else if (rainfallScore >= 91 && rainfallScore <= 100) {
              return `A productivity score of <b>${rainfallScore}%</b> for ${cropNameSelected} suggests a <b>minimum production risk</b>. A loan can be disbursed with optional insurance coverage.`;
            } else {
              return `Invalid score.`;
            }
          })()}
        </span>
      </td>
    </tr>
    
      </tbody>
    </table>
    <table style="width: 100%; border-collapse: collapse; margin-top: 20px; border-radius: 10px; overflow: hidden;">
    <thead>
      <tr>
        <th style="text-align: left; font-size: 14px; font-weight: bold; border: 1px solid #ddd; padding: 8px; background-color: lightgrey;">
          CROP: ${TemperatureCropNameSelected} | SCORE: ${temperatureScore}%
        </th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td style="border: 1px solid #ddd; padding: 8px; background-color: rgba(217, 217, 217, 0.30); font-size: 12px; color: #555;">
          <span style="display: inline-block; width: 28px; height: 28px; background-color: #87CEFA; color: white; border-radius: 50%; text-align: center; line-height: 28px; font-size: 16px; font-weight: bold; margin-right: 10px;">i</span>
          <span style="display: inline-block; vertical-align: top; max-width: 90%; word-wrap: break-word;">
            ${(() => {
              if (temperatureScore >= 0 && temperatureScore <= 40) {
                return `A productivity score of <b> ${temperatureScore}% </b>, for ${TemperatureCropNameSelected} suggests an <b>extremely high production risk</b> and is not advisable for loan disbursement.`;
              } else if (temperatureScore >= 41 && temperatureScore <= 65) {
                return `A productivity score of <b> ${temperatureScore}% </b>for ${TemperatureCropNameSelected} suggests a <b> high production risk</b>. A loan should be disbursed with mandatory insurance coverage.`;
              } else if (temperatureScore >= 66 && temperatureScore <= 90) {
                return `A productivity score of <b> ${temperatureScore}% </b>for ${TemperatureCropNameSelected} suggests a <b>moderate production risk</b>. A loan can be disbursed with optional insurance coverage.`;
              } else if (temperatureScore >= 91 && temperatureScore <= 100) {
                return `A productivity score of <b> ${temperatureScore}% </b>for ${TemperatureCropNameSelected} suggests a <b>minimum production risk</b>. A loan can be disbursed with optional insurance coverage.`;
              } else {
                return `Invalid score.`;
              }
            })()}
          </span>
        </td>
      </tr>
    </tbody>
  </table>
  </fieldset>
  <fieldset style="margin-top: 40px; border: 1px solid #ddd; padding: 12px; border-radius: 10px; position: relative;">
  <legend style="position: absolute; top: -10px; left: 15px; background-color: white; padding: 0 10px; color: grey; font-weight: bold;">PRODUCTION REQUIREMENTS (${cropNameSelected})</legend>
  <table style="width: 100%; border-collapse: collapse; margin-top: 8px; border-radius: 10px; overflow: hidden;">
    <thead>
      <tr>
        <th style="border: 1px solid #ddd; padding: 8px; text-align: left; background-color: lightgrey;">Factor</th>
        <th style="border: 1px solid #ddd; padding: 8px; text-align: left; background-color: lightgrey;">Requirement</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td style="border: 1px solid #ddd; padding: 8px; background-color: rgba(217, 217, 217, 0.30);">Temperature</td>
        <td style="border: 1px solid #ddd; padding: 8px; background-color: rgba(217, 217, 217, 0.30);">20-30°C (68-86°F)</td>
      </tr>
      <tr>
        <td style="border: 1px solid #ddd; padding: 8px;background-color: rgba(217, 217, 217, 0.30)">Rainfall</td>
        <td style="border: 1px solid #ddd; padding: 8px;background-color: rgba(217, 217, 217, 0.30)">500-800 mm</td>
      </tr>
      <tr>
        <td style="border: 1px solid #ddd; padding: 8px; background-color: rgba(217, 217, 217, 0.30);">Growing Season</td>
        <td style="border: 1px solid #ddd; padding: 8px; background-color: rgba(217, 217, 217, 0.30);">90-120 days</td>
      </tr>
      <tr>
        <td style="border: 1px solid #ddd; padding: 8px; background-color: rgba(217, 217, 217, 0.30)">Soil Type</td>
        <td style="border: 1px solid #ddd; padding: 8px; background-color: rgba(217, 217, 217, 0.30)">Well-drained loam</td>
      </tr>
      <tr>
        <td style="border: 1px solid #ddd; padding: 8px; background-color: rgba(217, 217, 217, 0.30);">pH Level</td>
        <td style="border: 1px solid #ddd; padding: 8px; background-color: rgba(217, 217, 217, 0.30);">5.5-7.0</td>
      </tr>
    </tbody>
  </table>
</fieldset>

  
      <!-- Fourth Fieldset: CROP SUITABILITY RANK -->
      <fieldset style="margin-top: 15px; border: 1px solid #ddd; padding: 12px; border-radius: 10px; position: relative;">
        <legend style="position: absolute; top: -10px; left: 15px; background-color: white; padding: 0 10px; color: grey; font-weight: bold;">CROP SUITABILITY RANK (Rainfall)</legend>
        <table style="width: 100%; border-collapse: collapse; margin-top: 8px; border-radius: 10px; overflow: hidden;">
        <thead>
          <tr>
            <th style="border: 1px solid #ddd; padding: 8px; text-align: left; background-color: lightgrey;">Crop</th>
            <th style="border: 1px solid #ddd; padding: 8px; text-align: left; background-color: lightgrey;">Variety</th>
            <th style="border: 1px solid #ddd; padding: 8px; text-align: left; background-color: lightgrey;">Credit Productivity Score</th>
          </tr>
        </thead>
        <tbody>
          ${suitabilityRows}
        </tbody>
      </table>
  
    </fieldset>
    <fieldset style="margin-top: 15px; border: 1px solid #ddd; padding: 12px; border-radius: 10px; position: relative;">
    <legend style="position: absolute; top: -10px; left: 15px; background-color: white; padding: 0 10px; color: grey; font-weight: bold;">CROP SUITABILITY RANK (Temperature)</legend>
    <table style="width: 100%; border-collapse: collapse; margin-top: 8px; border-radius: 10px; overflow: hidden;">
      <thead>
        <tr>
          <th style="border: 1px solid #ddd; padding: 8px; background-color: lightgrey;">Crop</th>
          <th style="border: 1px solid #ddd; padding: 8px; background-color: lightgrey;">Variety</th>
          <th style="border: 1px solid #ddd; padding: 8px; background-color: lightgrey;">Credit Productivity Score</th>
        </tr>
      </thead>
      <tbody>
        ${temperatureRows}
      </tbody>
    </table>
  </fieldset>
    </div>
  `;
}

export default getBimodalContent;
