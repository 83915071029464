import React, { useState, useEffect } from "react";
import "../../mainScreen.css";
import { FaUserAlt } from "react-icons/fa";
import { IoMdHome, IoMdMenu } from "react-icons/io";
import { IoIosSettings } from "react-icons/io";
import { Auth } from "aws-amplify";
import {
  MdOutlineKey,
  MdOutlineRemoveRedEye,
  MdEdit,
  MdDelete,
} from "react-icons/md";
import { IoLogOutOutline } from "react-icons/io5";
import { FaCircleUser, FaChevronUp } from "react-icons/fa6";
import { IoSettingsOutline } from "react-icons/io5";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tabs,
  Tab,
  Box,
  TextField,
} from "@mui/material";
import AddUser from "./AddUser";
import { IoIosArrowForward, IoIosLogOut } from "react-icons/io";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";

const Settings = (props) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [addUserScreen, setaddUserScreen] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [activeTab, setActiveTab] = useState(0); // 0 for "User Role", 1 for "User Group"
  const [userGroups, setUserGroups] = useState([]); // New state for storing user group data
  const [userRoles, setUserRoles] = useState([]); // State for storing user role data
  const LoginEmail = useSelector((state) => state.LoginEmail);
  const enterprise = useSelector((state) => state.enterprise);
  const [selectedRow, setSelectedRow] = useState(null);

  const handleEdit = (role) => {
    setSelectedRow(role);
    props.setaddUserRoleScreen(true)
    props.setadminSettingsScreen(false)
    props.setSelectedUserRoleRow(role);
    //console.log("Selected Role for Edit:", role);
  };
  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const handleMenuToggle = () => {
    setIsCollapsed(!isCollapsed);
  };

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const handleLogout = async () => {
    try {
      await Auth.signOut();
      window.location.reload();
    } catch (error) {
      console.log("error signing out: ", error);
    }
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };
  const fetchUserRoles = async () => {
    try {
      const { accessToken: { jwtToken } } = await Auth.currentSession();
      const response = await fetch("/api/admin/getAllUserRole", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": jwtToken, 
        },
        body: JSON.stringify({
          admin_email: LoginEmail, // Admin email fetched dynamically
          enterprise:enterprise
        }),
      });
      if (response.ok) {
        const data = await response.json();
        setUserRoles(data); // Store API response in userRoles state
      } else {
        toast.error("Failed to fetch user roles");
      }
    } catch (error) {
      console.error("Error fetching user roles:", error);
      toast.error("An error occurred while fetching user roles");
    }
  };
  
  const fetchUserGroups = async () => {
    try {
      const { accessToken: { jwtToken } } = await Auth.currentSession();
      const response = await fetch("/api/admin/getAllGroup", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": jwtToken, 
        },
        body: JSON.stringify({
          admin_email: LoginEmail, // Admin email fetched dynamically
          enterprise:enterprise
        }),
      });
      if (response.ok) {
        const data = await response.json();
        setUserGroups(data); // Store API response in userGroups state
      } else {
        toast.error("Failed to fetch user groups");
      }
    } catch (error) {
      console.error("Error fetching user groups:", error);
      toast.error("An error occurred while fetching user groups");
    }
  };
  
  useEffect(() => {
    if (activeTab === 0) {
      fetchUserRoles(); // Fetch user roles when User Role tab is selected
    } else if (activeTab === 1) {
      fetchUserGroups(); // Fetch user groups when User Group tab is selected
    }
  }, [activeTab]);

  const users = [
    {
      username: "Josephine Kamoli",
      email: "josephine.kamoli@tara.com",
      role: "Underwriter",
      functionality: "Full Access",
      group: "User",
    },
  ];

  const handleDeleteUser = async (role) => {
    try {
      const response = await fetch("api/admin/deleteUserRole", {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ user_role: role ,'enterprise':enterprise,'admin_email':LoginEmail}), // Changed role to user_role
      });

      if (response.ok) {
        toast.success("User deleted successfully");
      } else {
        toast.error("Failed to delete user");
      }
    } catch (error) {
      console.error("Error deleting user:", error);
      toast.error("An error occurred while deleting the user");
    }
  };

  const handleDeleteGroup = async (group) => {
    try {
      const response = await fetch("api/admin/deleteGroup", {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ group_name: group,'enterprise':enterprise,'admin_email':LoginEmail }), // Changed role to user_role
      });

      if (response.ok) {
        toast.success("Group deleted successfully");
      } else {
        toast.error("Failed to delete Group");
      }
    } catch (error) {
      console.error("Error deleting Group:", error);
      toast.error("An error occurred while deleting the group");
    }
  };

  const filteredUsers = users.filter((user) =>
    user.username.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="main-screen" style={{ display: "flex" }}>
      {/* Sidebar Section */}
      <div
        style={{
          background: "#00253D",
          height: "100vh",
          zIndex: "1001",
          position: "relative",
          width: isCollapsed ? "80px" : "auto",
          transition: "width 0.3s",
          alignItems: "flex-start",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            background: "#00253D",
            height: "100vh",
            zIndex: "1001",
            position: "relative",
            width: isCollapsed ? "80px" : "auto", // Adjust width for collapsed state
            transition: "width 0.3s",
            alignItems: "flex-start",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <div style={{ width: "100%" }}>
            <div
              style={{
                padding: "20px",
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <IoMdMenu color="white" size={28} onClick={handleMenuToggle} />
              {!isCollapsed && (
                <span
                  style={{
                    marginLeft: "10px",
                    color: "white",
                    fontSize: "22px",
                  }}
                >
                  TARA
                </span>
              )}
            </div>

            {/* Sidebar Links */}

            <div
              className="custom-buttonnav"
              style={{
                padding: "20px",
                display: "flex",
                cursor: "pointer",
              }}
              onClick={() => {
                props.setadminProfilescreen(true);
                props.setaddUserRoleScreen(false);
                props.setaddUserGroupScreen(false);
                props.setadminSettingsScreen(false);
                props.setoutputportfolioscreen(false);
              }}
            >
              <FaUserAlt
                color="white"
                size={28}
                style={{ marginRight: isCollapsed ? "0" : "10px" }}
              />
              {!isCollapsed && (
                <span style={{ color: "white", fontSize: "18px" }}>
                  User Management
                </span>
              )}
            </div>

            <div
              className="custom-buttonnav"
              style={{
                padding: "20px",
                display: "flex",
                cursor: "pointer",
                background: "#F5821F",
              }}
            >
              <IoIosSettings
                color="white"
                size={28}
                style={{ marginRight: isCollapsed ? "0" : "10px" }}
              />
              {!isCollapsed && (
                <span style={{ color: "white", fontSize: "18px" }}>
                  User Settings
                </span>
              )}
            </div>
            {enterprise && !isCollapsed && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "auto",
                  position: "relative",
                  marginTop: "20px",
                  margin: enterprise === "acre" ? "10px" : "0",
                  backgroundColor: enterprise === "acre" ? "white" : "none",
                }}
              >
                <img
                  src={
                    enterprise === "acre"
                      ? "acre.png"
                      : enterprise === "digifarm"
                      ? "digifarm.png"
                      : enterprise === "sbm"
                      ? "sbm.png"
                      : enterprise === "ncba"
                      ? "ncba3.png"
                      : "default.png"
                  }
                  alt="Enterprise Logo"
                  style={{ width: "90px", height: "auto" }}
                />
              </div>
            )}
          </div>

          <div style={{ position: "relative", width: "100%" }}>
            <div
              className="custom-buttonnav"
              style={{
                padding: "15px",
                display: "flex",
                width: "auto",
                cursor: "pointer",
                alignItems: "center",
                borderTop: "2px solid white",
                position: "relative",
              }}
              onClick={togglePopup}
            >
              {showPopup && (
                <div
                  style={{
                    position: "absolute",
                    top: "-110px",
                    left: "10px",
                    backgroundColor: "white",
                    color: "black",
                    padding: "10px",
                    borderRadius: "5px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    zIndex: "1000",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "5px 0",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      props.setPasswordSettingscreen(false);
                      props.setadminProfilescreen(false);
                      props.setadvisorycreen(false);
                      props.setadminSettingsScreen(false);
                      props.setMainscreen(false);
                      props.setcalulateriskmainscreen(false);
                      props.setoutputportfolioscreen(true);
                    }}
                  >
                    <IoSettingsOutline style={{ marginRight: "8px" }} /> Switch
                    to user
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "5px 0",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      props.setadminSettingsScreen(false);
                      props.setPasswordSettingscreen(true);
                      props.setadminProfilescreen(false);
                      props.setmonitorscreen(false);
                      props.setMainscreen(false);
                      props.setcalulateriskmainscreen(false);
                      props.setoutputportfolioscreen(false);
                    }}
                  >
                    <IoSettingsOutline style={{ marginRight: "8px" }} />{" "}
                    Settings
                  </div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "5px 0",
                      cursor: "pointer",
                    }}
                    onClick={handleLogout}
                  >
                    <IoLogOutOutline style={{ marginRight: "8px" }} /> Logout
                  </div>
                </div>
              )}

              <FaCircleUser
                color="white"
                size={25}
                style={{ marginRight: isCollapsed ? "0" : "8px" }}
              />
              {!isCollapsed && (
                <>
                  <span
                    style={{
                      color: "white",
                      fontSize: "12px",
                    }}
                  >
                    {LoginEmail}
                  </span>
                  <IoIosArrowForward color="white" size={20} />
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Main Content Section */}
      <div style={{ flex: 1, padding: "20px" }}>
        {/* User Management Header */}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <h1 style={{ fontSize: "28px", fontWeight: "bold" }}>
            Users Settings
          </h1>
        </div>

        {/* Tabs Section */}
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={activeTab} onChange={handleTabChange}>
            <Tab label="User Role" />
            <Tab label="User Group" />
          </Tabs>
        </Box>

        {/* Tab Content */}
        {activeTab === 0 && (
          <div>
            {/* User Role Tab Content */}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "20px",
              }}
            >
              <TextField
                variant="outlined"
                placeholder="Search user roles..."
                style={{
                  padding: "10px",
                  width: "620px",
                  boxSizing: "border-box",
                }}
              />
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#00253D",
                  color: "white",
                  padding: "10px 20px",
                  width: "172px",
                }}
                onClick={() => {
                  props.setadminProfilescreen(false);
                  props.setadvisorycreen(false);
                  props.setmonitorscreen(false);
                  props.setMainscreen(false);
                  props.setcalulateriskmainscreen(false);
                  props.setaddUserRoleScreen(true);
                  props.setaddUserGroupScreen(false);
                  props.setadminSettingsScreen(false);
                 props.setSelectedUserRoleRow(null);

                }}
              >
                Add User Role
              </Button>
            </div>

            {/* User Role Table */}
            <TableContainer component={Paper}>
              <Table>
                <TableHead style={{ backgroundColor: "lightgrey" }}>
                  <TableRow>
                    <TableCell>User Role</TableCell>
                    <TableCell>Date Entered</TableCell>
                    <TableCell>Functionality</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
          {userRoles
            .filter((role) =>
              role.Role.toLowerCase().includes(searchTerm.toLowerCase())
            )
            .map((role, index) => (
              <TableRow key={index}>
                <TableCell>{role.Role}</TableCell>
                <TableCell>{role.Date || "N/A"}</TableCell>
                <TableCell>
                  {role.functionality.split(";").map((func, idx) => (
                    <div key={idx}>{func}</div>
                  ))}
                </TableCell>
                <TableCell>
                  <Button onClick={() => handleEdit(role)}>
                    <MdEdit />
                  </Button>
                  <Button onClick={() => handleDeleteUser(role.Role)}>
                    <MdDelete />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
              </Table>
            </TableContainer>
            <ToastContainer />
          </div>
        )}

        {activeTab === 1 && (
          <div>
            {/* User Group Tab Content (Placeholder) */}
            <div>
              {/* User Role Tab Content */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "20px",
                }}
              >
                <TextField
                  variant="outlined"
                  placeholder="Search user group..."
                  style={{
                    padding: "10px",
                    width: "620px",
                    boxSizing: "border-box",
                  }}
                />
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: "#00253D",
                    color: "white",
                    padding: "10px 20px",
                    width: "172px",
                  }}
                  onClick={() => {
                    props.setadminProfilescreen(false);
                    props.setadvisorycreen(false);
                    props.setmonitorscreen(false);
                    props.setMainscreen(false);
                    props.setcalulateriskmainscreen(false);
                    props.setaddUserGroupScreen(true);
                    props.setaddUserRoleScreen(false);
                    props.setadminSettingsScreen(false);
                  }}
                >
                  Add User Group
                </Button>
              </div>

              {/* User Role Table */}
              <TableContainer component={Paper}>
                <Table>
                  <TableHead style={{ backgroundColor: "lightgrey" }}>
                    <TableRow>
                      <TableCell>User Group</TableCell>
                      <TableCell>Date Entered</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {userGroups.map((group, index) => (
                      <TableRow key={index}>
                        <TableCell>{group.group}</TableCell>
                        <TableCell>{group.date}</TableCell>
                        <TableCell>
                          <Button
                            onClick={() => handleDeleteGroup(group.group)}
                          >
                            <MdDelete />
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <ToastContainer />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Settings;
