import React from 'react';

const RiskColorTable = (props) => {
  const { averagePercentage } = props;

  const getEligibilityAdvice = () => {
    if (averagePercentage === null || averagePercentage === undefined || averagePercentage === '') {
      return null; // Indicates loading
    }

    if (averagePercentage >= 0 && averagePercentage <= 40) {
      return {
        level: "Very High",
        color: "#FD402C",
        advice: "Crop has extremely high production risk and not advisable for loan disbursement",
      };
    } else if (averagePercentage >= 41 && averagePercentage <= 65) {
      return {
        level: "High",
        color: "#FEB703",
        advice: "Crop has high production risk and loan should be disbursed with mandatory insurance coverage",
      };
    } else if (averagePercentage >= 66 && averagePercentage <= 90) {
      return {
        level: "Low",
        color: "#02B801",
        advice: "Crop has moderate production risk and loan can be disbursed with optional insurance coverage",
      };
    } else if (averagePercentage >= 91 && averagePercentage <= 100) {
      return {
        level: "Very Low",
        color: "#026B01",
        advice: "Crop has minimum production risk and loan can be disbursed with optional insurance coverage",
      };
    } else {
      return null; // Invalid range
    }
  };

  const advice = getEligibilityAdvice();

  return (
    <>
      {/* <div
        style={{
          height: '20px',
          width: '90%',
          borderRadius: '5px',
          position: 'relative',
          alignItems: 'center',
          marginBottom: '50px',
        }}
      >
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: '5%',
            height: '100%',
            width: '25%',
            background: '#FD402C',
            borderRadius: '5px 0 0 5px',
          }}
        ></div>
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: '30%',
            height: '100%',
            width: '25%',
            background: '#FEB703',
          }}
        ></div>
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: '55%',
            height: '100%',
            width: '25%',
            background: '#02B801',
          }}
        ></div>
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: '80%',
            height: '100%',
            width: '25%',
            background: '#026B01',
            borderRadius: '0 5px 5px 0',
          }}
        ></div>
        <div style={{ position: 'absolute', left: '10%', top: '100%', fontSize: '12px', color: '#000' }}>0-40</div>
        <div style={{ position: 'absolute', left: '40%', top: '100%', fontSize: '12px', color: '#000' }}>41-65</div>
        <div style={{ position: 'absolute', left: '65%', top: '100%', fontSize: '12px', color: '#000' }}>66-90</div>
        <div style={{ position: 'absolute', left: '90%', top: '100%', fontSize: '12px', color: '#000' }}>91-100</div>
      </div> */}

      <h2
        style={{
          fontSize: '16px',
          fontWeight: 700,
          lineHeight: '26.56px',
          marginBottom: '20px',
          textAlign: 'left',
          marginLeft: '10px',
        }}
      >
        LOAN ADVISORY SUMMARY
      </h2>
      {averagePercentage === null || averagePercentage === undefined || averagePercentage === '' ? (
        <div
          style={{
            fontSize: '15px',
            color: '#000',
            fontWeight: 'bold',
            padding: '20px',
            textAlign: 'center',
          }}
        >
          Loading...
        </div>
      ) : advice ? (
        <div
          style={{
            fontSize: '15px',
            color: advice.color,
            fontWeight: 'bold',
            padding: '20px',
          }}
        >
          <strong>{advice.level}:</strong> {advice.advice}
        </div>
      ) : (
        <div
          style={{
            fontSize: '15px',
            color: '#000',
            textAlign: 'center',
            fontWeight: 'bold',
            padding: '20px',
          }}
        >
          Invalid percentage range.
        </div>
      )}
    </>
  );
};

export default RiskColorTable;
