import React, { useState } from "react";
import "../../mainScreen.css";
import { IoMdMenu } from "react-icons/io";
import { IoIosSettings } from "react-icons/io";
import { Auth } from "aws-amplify";
import {
  MdEdit,
  MdDelete,
  MdVisibility,
  MdVisibilityOff,
} from "react-icons/md";
import { IoLogOutOutline } from "react-icons/io5";
import { FaCircleUser, FaChevronUp } from "react-icons/fa6";
import { IoSettingsOutline } from "react-icons/io5";
import {
  Button,
  Box,
  TextField,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { IoIosArrowForward } from "react-icons/io";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";

const PasswordSettings = (props) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [addUserScreen, setaddUserScreen] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [activeTab, setActiveTab] = useState(0);
  const [email, setEmail] = useState("");
  const [temporaryPassword, setTemporaryPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [showTemporaryPassword, setShowTemporaryPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const LoginEmail = useSelector((state) => state.LoginEmail);
  const enterprise = useSelector((state) => state.enterprise);

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const handleMenuToggle = () => {
    setIsCollapsed(!isCollapsed);
  };

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const handleLogout = async () => {
    try {
      await Auth.signOut();
      window.location.reload();
    } catch (error) {
      console.log("error signing out: ", error);
    }
  };

  const handleSubmit = async () => {
    try {
      const { accessToken: { jwtToken } } = await Auth.currentSession();
      const response = await fetch("/api/admin/updatePassword", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
           Authorization: jwtToken,
        },
        body: JSON.stringify({
          username: email,
          new_password: newPassword,
        }),
      });

      if (response.ok) {
        toast.success("Password updated successfully!");
      } else {
        const errorData = await response.json();
        toast.error(`Failed to update password`);
      }
    } catch (error) {
      toast.error(`An error occurred: ${error.message}`);
    }
  };

  return (
    <div className="main-screen" style={{ display: "flex" }}>
      <div
        style={{
          background: "#00253D",
          height: "100vh",
          zIndex: "1001",
          position: "relative",
          width: isCollapsed ? "80px" : "auto",
          transition: "width 0.3s",
          alignItems: "flex-start",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            background: "#00253D",
            height: "100vh",
            zIndex: "1001",
            position: "relative",
            width: isCollapsed ? "80px" : "auto", // Adjust width for collapsed state
            transition: "width 0.3s",
            alignItems: "flex-start",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <div style={{ width: "100%" }}>
            <div
              style={{
                padding: "20px",
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <IoMdMenu color="white" size={28} onClick={handleMenuToggle} />
              {!isCollapsed && (
                <span
                  style={{
                    marginLeft: "10px",
                    color: "white",
                    fontSize: "22px",
                  }}
                >
                  TARA
                </span>
              )}
            </div>

            {/* Sidebar Links */}

            <div
              className="custom-buttonnav"
              style={{
                padding: "20px",
                display: "flex",
                cursor: "pointer",
                background: "#F5821F",
              }}
            >
              <IoIosSettings
                color="white"
                size={28}
                style={{ marginRight: isCollapsed ? "0" : "10px" }}
              />
              {!isCollapsed && (
                <span style={{ color: "white", fontSize: "18px" }}>
                  Settings
                </span>
              )}
            </div>
            {enterprise && !isCollapsed && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "auto",
                  position: "relative",
                  marginTop: "20px",
                  margin: enterprise === "acre" ? "10px" : "0",
                  backgroundColor: enterprise === "acre" ? "white" : "none",
                }}
              >
                <img
                  src={
                    enterprise === "acre"
                      ? "acre.png"
                      : enterprise === "digifarm"
                      ? "digifarm.png"
                      : enterprise === "sbm"
                      ? "sbm.png"
                      : enterprise === "ncba"
                      ? "ncba3.png"
                      : "default.png"
                  }
                  alt="Enterprise Logo"
                  style={{ width: "90px", height: "auto" }}
                />
              </div>
            )}
          </div>

          <div style={{ position: "relative", width: "100%" }}>
            <div
              className="custom-buttonnav"
              style={{
                padding: "15px",
                display: "flex",
                width: "auto",
                cursor: "pointer",
                alignItems: "center",
                borderTop: "2px solid white",
                position: "relative",
              }}
              onClick={togglePopup}
            >
              {showPopup && (
                <div
                  style={{
                    position: "absolute",
                    top: "-110px",
                    left: "10px",
                    backgroundColor: "white",
                    color: "black",
                    padding: "10px",
                    borderRadius: "5px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    zIndex: "1000",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "5px 0",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      props.setadminProfilescreen(false);
                      props.setadvisorycreen(false);
                      props.setmonitorscreen(false);
                      props.setMainscreen(false);
                      props.setcalulateriskmainscreen(false);
                      props.setPasswordSettingscreen(false);
                      props.setoutputportfolioscreen(true);
                    }}
                  >
                    <IoSettingsOutline style={{ marginRight: "8px" }} /> Switch
                    to user
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "5px 0",
                      cursor: "pointer",
                    }}
                  >
                    <IoSettingsOutline style={{ marginRight: "8px" }} />{" "}
                    Settings
                  </div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "5px 0",
                      cursor: "pointer",
                    }}
                    onClick={handleLogout}
                  >
                    <IoLogOutOutline style={{ marginRight: "8px" }} /> Logout
                  </div>
                </div>
              )}

              <FaCircleUser
                color="white"
                size={25}
                style={{ marginRight: isCollapsed ? "0" : "8px" }}
              />
              {!isCollapsed && (
                <>
                  <span
                    style={{
                      color: "white",
                      fontSize: "12px",
                    }}
                  >
                    {LoginEmail}
                  </span>
                  <IoIosArrowForward color="white" size={20} />
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Main Content Section */}
      <div style={{ flex: 1, padding: "20px" }}>
        {/* User Management Header */}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <h1 style={{ fontSize: "28px", fontWeight: "bold" }}>
            Password Settings
          </h1>
        </div>

        {/* Input Fields and Submit Button */}
        <Box
          component="form"
          noValidate
          autoComplete="off"
          style={{ marginTop: "20px" , width:'60%'}}
        >
          <TextField
            label="Email"
            variant="outlined"
            fullWidth
            margin="normal"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            label="Temporary Password"
            variant="outlined"
            fullWidth
            margin="normal"
            type={showTemporaryPassword ? "text" : "password"}
            value={temporaryPassword}
            onChange={(e) => setTemporaryPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() =>
                      setShowTemporaryPassword(!showTemporaryPassword)
                    }
                    edge="end"
                  >
                    {showTemporaryPassword ? (
                      <MdVisibilityOff />
                    ) : (
                      <MdVisibility />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            label="New Password"
            variant="outlined"
            fullWidth
            margin="normal"
            type={showNewPassword ? "text" : "password"}
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowNewPassword(!showNewPassword)}
                    edge="end"
                  >
                    {showNewPassword ? <MdVisibilityOff /> : <MdVisibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            style={{ marginTop: "20px" }}
          >
            Submit
          </Button>
        </Box>

        <ToastContainer />
      </div>
    </div>
  );
};

export default PasswordSettings;
