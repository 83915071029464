import React, { useEffect, useState } from 'react';
import '../outputIndividual.css';
import { FormControl, InputLabel, Select, MenuItem, Checkbox, ListItemText, TextField, Autocomplete } from '@mui/material';
import { useDispatch, useSelector } from "react-redux";
import { changeDistrictName, changeLevel3Name, changeLevel4Name, changeStateName, changeTableAdvisoryData, changeproductionDate, changeLatitude, changeLongitude, changeCropNameSelected, changeCountryName } from '../redux/action';
import { marked } from 'marked';
import { Auth } from "aws-amplify";


const AdvisoryTable = (props) => {
  const [clickedButton, setClickedButton] = useState('default');
  const [countryName, setCountryName] = useState("Kenya");
  const [states, setStates] = useState([]);
  const [crop, setcrop] = useState([]);
  const [cropVariety, setcropVariety] = useState([]);
  const stateName = useSelector((state) => state.stateName);
  const [districts, setDistricts] = useState([]);
  const districtName = useSelector((state) => state.districtName);
  const [level4, setLevel4] = useState([]);
  const level4Data = useSelector((state) => state.level4Data);
  const [level3, setLevel3] = useState([]);
  const level3Data = useSelector((state) => state.level3Data);
  const availableCountries = ["Kenya"];
  const dispatch = useDispatch();
  const productionDateSelected = useSelector((state) => state.productionDateSelected);
  const [activity, setactivity] = useState([]);
  const region = `${countryName} > ${stateName} > ${districtName} > ${level3Data}`;
  const cropvarietyandcrop = `${props.selectedcrop} > ${props.selectedcropvariety}`;
  const countryObject = availableCountries.map((country) => ({ label: country, value: country }));
  const cropObject = crop.map((level) => ({ label: level, value: level }));
  const cropVarietyObject = cropVariety.map((level) => ({ label: level, value: level }))
  const activityOptions = activity.map((level) => ({ label: level, value: level }))
  const [season, setSeason] = React.useState('');


  useEffect(() => {
    async function fetchLocationData() {
      let levelName = "";
      if (countryName === "Tanzania" || countryName === "Sudan") {
        levelName = "state";
      } else if (countryName === "Zambia") {
        levelName = "province";
      } else if (countryName === "Bangladesh") {
        levelName = "division";
      } else if (countryName === "Kenya") {
        levelName = "county";
      }
      if (countryName) {
        const stateResponse = await fetch(`/api/homepage/selectLocation?country=${countryName}&level=${levelName}`);
        const statesData = await stateResponse.json();
        setStates(statesData);
        if (stateName) {
          let level2Name = "";
          if (countryName === "Tanzania" || countryName === "Zambia" || countryName === "Sudan") {
            level2Name = "district";
          } else if (countryName === "Bangladesh") {
            level2Name = "zila";
          } else if (countryName === "Kenya") {
            level2Name = "constituency";
          }
          const districtResponse = await fetch(`/api/homepage/selectLocation?country=${countryName}&${levelName}=${stateName}&level=${level2Name}`);
          const districtsData = await districtResponse.json();
          setDistricts(districtsData);
          if (districtName) {
            let level3Name = "";
            if (countryName === "Tanzania" || countryName === "Kenya" || countryName === "Sudan") {
              level3Name = "ward";
            } else if (countryName === "Zambia") {
              level3Name = "block";
            } else if (countryName === "Bangladesh") {
              level3Name = "upazila";
            }
            const level3Response = await fetch(`/api/homepage/selectLocation?country=${countryName}&${levelName}=${stateName}&${level2Name}=${districtName}&level=${level3Name}`);
            const level3Data = await level3Response.json();
            setLevel3(level3Data);

            if (level3Data && (countryName === "Zambia" || countryName === "Bangladesh")) {
              let level4Name = "";
              if (countryName === "Zambia") {
                level4Name = "camp";
              } else if (countryName === "Bangladesh") {
                level4Name = "union";
              }

              const level4Response = await fetch(`/api/homepage/selectLocation?country=${countryName}&${levelName}=${stateName}&${level2Name}=${districtName}&${level3Name}=${level3Data}&level=${level4Name}`);
              const level4Data = await level4Response.json();
              setLevel4(level4Data);
            }
          }
        }
      }
    }
    fetchLocationData();
  }, [countryName, districtName, stateName, level3Data, level4Data]);


  useEffect(() => {
    async function fetchData() {
      try {
        const { accessToken: { jwtToken } } = await Auth.currentSession();
        const datatypeResponse = await fetch(`/api/advisory/selectCrop`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: jwtToken,
          },
        });
        if (datatypeResponse.ok) {
          const datatypedata = await datatypeResponse.json();
          setcrop(datatypedata["crops"]);
        } else {
          console.error('Failed to fetch data types');
        }
      } catch (error) {
        console.error('Error fetching data types:', error);
      }
      try {
        const { accessToken: { jwtToken } } = await Auth.currentSession();
        const activityResponse = await fetch(`/api/advisory/selectActivity?api_key=49c961d98c69bf92c4536d8b36bbaca85cedaf906834c85343861a9dc5475eba`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: jwtToken,

          },
        });
        if (activityResponse.ok) {
          const activitydata = await activityResponse.json();
          setactivity(activitydata["message"]);
        } else {
          console.error('Failed to fetch data types');
        }
      } catch (error) {
        console.error('Error fetching data types:', error);
      }
    }
    fetchData();
  }, []);


  useEffect(() => {
    async function fetchData() {
      if (props.selectedcrop) {
        props.setAdvisoryError('');
        props.setDefaultAdvisory([]);
        try {
          const { accessToken: { jwtToken } } = await Auth.currentSession();
          const datatypeResponse = await fetch(`/api/advisory/selectCropVariety?crop_name=${props.selectedcrop}`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: jwtToken,

            },
          });
          if (datatypeResponse.ok) {
            const datatypedata = await datatypeResponse.json();
            setcropVariety(datatypedata["variety"]);
          } else {
            console.error('Failed to fetch data types');
          }
        } catch (error) {
          console.error('Error fetching data types:', error);
        }
      }
    }
    fetchData();
  }, [props.selectedcrop]);

  const handleseasonchange = (event) => {
    const selectedSeason = event.target.value;
    setSeason(selectedSeason);
  
    // Reset production date if "Custom" is selected
    if (selectedSeason === "Custom") {
        dispatch(changeproductionDate(""));
    } else {
        // Set production date based on selected season
        const productionstartDate = selectedSeason === "Long Rain" ? "2024-03-01" : "2024-11-01";
        dispatch(changeproductionDate(productionstartDate));
    }
};
  const handleSubmit = () => {
    let url = '';
    const activitiesParam = JSON.stringify(props.selectedActivities);
    if (props.defaultscreen) {
      url = `/api/advisory/get_advisory?latitude=${props.latitude}&longitude=${props.longitude}&crop=${props.selectedcrop}&crop_variety=${props.selectedcropvariety}&planting_date=${encodeURIComponent(productionDateSelected)}&api_key=7c0446b12792e247498792a21fc3834275621b7a86e101347170815c28c5ad68&activities=${encodeURIComponent(activitiesParam)}`;
    }
    else if (props.AIpoweredscreen) {
      props.setAIadvisory('')
      url = `/api/advisory/get_advisory_with_ai`;
      const objectToQueryString = (obj) =>
        Object.keys(obj)
          .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
          .join('&');
      // Set up query parameters
      const queryParams = {
        region: region || '',
        crop_variety: cropvarietyandcrop || '',
        planting_date: productionDateSelected || '',
        api_key: "49c961d98c69bf92c4536d8b36bbaca85cedaf906834c85343861a9dc5475eba" || '',
        activities: JSON.stringify(props.selectedActivities) || '',
      };
      // Construct final URL
      const finalUrl = `${url}?${objectToQueryString(queryParams)}`;
      // Initialize EventSource
      const eventSource = new EventSource(finalUrl);
      // Event listener for receiving messages
      eventSource.onmessage = (event) => {
        if (event.data.trim() === 'END') {
          eventSource.close();
        } else {
          console.log("data decrypted", marked(event.data.replace(/\\n/g, '\n')));
          // Process the received data
          if (shouldAddData(event.data)) {
            props.setAIadvisory((prevData) => prevData + marked(event.data.substring(9430).replace(/\\n/g, '\n')));
          }
        }
      };

      // Clean up when component unmounts or dependency changes
      return () => {
        eventSource.close();
      };
    }
    else {
      // Handle case where neither screen is true
      return;
    }
    if (props.defaultscreen) {
      fetch(url)
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          if (props.defaultscreen)
            return response.json();
        })
        .then(data => {
          if (props.defaultscreen == true && data["message"].length === 0) {
            props.setAdvisoryError(data["error"]);
            dispatch(changeTableAdvisoryData([]));
          } else {
            if (props.AIpoweredscreen) {
              //console.log(data)
              // Store response in state for AI advisory
              //props.setAIadvisory(data);

            } else {
              // Store response in default advisory state
              props.setDefaultAdvisory(data["message"]);
              dispatch(changeTableAdvisoryData(data["message"]));
            }
          }
        })
        .catch(error => {
          // Handle fetch or other network errors
          console.error('Error fetching data:', error);
          // Optionally, set error state if needed
          props.setAdvisoryError('Error fetching data. Please try again later.');
          dispatch(changeTableAdvisoryData([]));
        });
    }
  };

  const shouldAddData = (data) => {
    // Add your logic here. For example, if the data should not contain certain keywords
    // or if it should follow a certain format. Return true if the data should be added, false otherwise.
    // Example condition: Ignore data that seems to be encrypted (if it matches a certain pattern)
    const isLikelyEncrypted = (data) => {
      // Example pattern: base64-like strings are generally longer and may contain '=' padding
      return /^[A-Za-z0-9+/=]{20,}$/.test(data);
    };
    return !isLikelyEncrypted(data); // Only add data if it is not likely encrypted
  };


  const handleClick = (button) => {
    if (button === 'default') {
      props.setAIpoweredscreen(false)
      props.setdefaultscreen(true)
    } else if (button === 'aipowered') {
      props.setAIpoweredscreen(true)
      props.setdefaultscreen(false)
    }
    setClickedButton(button);
  };

  const handleClearMap = () => {
    dispatch(changeLatitude(""));
    dispatch(changeLongitude(""));
    props.setMap()
  }



  return (
    <div className="left-panel" style={{ background: 'white', padding: '20px', paddingBottom: '30px', height: 'auto' }}>
      <div className="heading" style={{ color: '#000', fontSize: '3.5vh', fontWeight: 900, lineHeight: '3vh', letterSpacing: '0.46px', textTransform: 'uppercase', marginBottom: '15px' }}>
        ADVISORY PARAMETERS
      </div>
     
      <div className="dataset-heading" style={{ color: '#000', fontSize: '13px', fontWeight: 500, lineHeight: '3vh', letterSpacing: '0.46px', textTransform: 'uppercase', marginBottom: '0.7vh' }}>
        LOCATION INFORMATION
      </div>
      <FormControl fullWidth>
        <InputLabel id="country-select-label">Country</InputLabel>
        <Select
          style={{ height: clickedButton === 'upload' ? '5.5vh' : '6vh', marginBottom: '2vh', fontSize: '14px', alignItems: 'center', overflow: 'visible' }}
          labelId="country-select-label"
          id="country-select"
          value={countryName}
          onChange={(event) => {
            setCountryName(event.target.value);
            dispatch(changeStateName(null));
            dispatch(changeDistrictName(null));
            dispatch(changeLevel3Name(null));
            dispatch(changeLevel4Name(null));
          }}
        >
          {countryObject.map((country) => (
            <MenuItem key={country.value} value={country.value}>
              {country.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth>
        <TextField
          label="Latitude"
          value={props.latitude || ''}  // Handle undefined or null
          onChange={(event) => {
            const value = event.target.value;
            // Ensure that only valid numeric values are allowed
            if (/^[0-9]*\.?[0-9]*$/.test(value)) {
              // Dispatch or handle the value change here, for example:
              props.setLatitude(value)
            }
          }}
          inputProps={{
            inputMode: 'decimal', // Suggests the numeric keyboard on mobile
            pattern: "[0-9]*\\.?[0-9]+", // Restricts the input to numbers and a single dot
          }}
          style={{
            height: clickedButton === 'upload' ? '5.5vh' : '6vh',
            marginBottom: '2vh',
            fontSize: '14px',
          }}
        />
      </FormControl>

      <FormControl fullWidth>
        <TextField
          label="Longitude"
          value={props.longitude || ''}  // Handle undefined or null
          onChange={(event) => {
            const value = event.target.value;
            // Ensure that only valid numeric values are allowed
            if (/^[0-9]*\.?[0-9]*$/.test(value)) {
              // Dispatch or handle the value change here, for example:
              props.setLongitude(value)
            }
          }}
          inputProps={{
            inputMode: 'decimal', // Suggests the numeric keyboard on mobile
            pattern: "[0-9]*\\.?[0-9]+", // Restricts the input to numbers and a single dot
          }}
          style={{
            height: clickedButton === 'upload' ? '5.5vh' : '6vh',
            marginBottom: '2vh',
            fontSize: '14px',
          }}
        />
      </FormControl>
      <div className="dataset-heading" style={{ color: '#000', fontSize: '13px', fontWeight: 500, lineHeight: '3vh', letterSpacing: '0.46px', textTransform: 'uppercase', marginBottom: '0.7vh', marginTop: '15px' }}>
        CROP INFORMATION
      </div>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Crop type</InputLabel>
        <Select
          style={{ height: clickedButton === 'upload' ? '5.5vh' : '6vh', marginBottom: '2vh', fontSize: '14px', alignItems: 'center' }}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: '300px',// this ensures the menu appears right below the select input
              }
            }
          }}
          label="Data type"
          value={props.selectedcrop}
          onChange={(event) => props.setselectedcrop(event.target.value)}
        >
          {cropObject.map((level) => (
            <MenuItem key={level.value} value={level.value}>
              {level.label}
            </MenuItem>
          ))}
        </Select>

      </FormControl>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Crop Variety</InputLabel>
        <Select
          style={{ height: clickedButton === 'upload' ? '5.5vh' : '6vh', marginBottom: '2vh', fontSize: '14px', alignItems: 'center' }}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: '300px',// this ensures the menu appears right below the select input
              }
            }
          }}
          label="Data type"

          value={props.selectedcropvariety}
          onChange={(event) => props.setselectedcropvariety(event.target.value)}
        >
          {cropVarietyObject.map((level) => (
            <MenuItem key={level.value} value={level.value}>
              {level.label}
            </MenuItem>
          ))}
        </Select>

      </FormControl>
      <div className="dataset-heading" style={{ color: '#000', fontSize: '13px', fontWeight: 500, lineHeight: '3vh', letterSpacing: '0.46px', textTransform: 'uppercase', marginBottom: '0.7vh', marginTop: '15px' }}>
       PRODUCTION/PLANTING PERIOD
      </div>
      <FormControl fullWidth>
            <InputLabel sx={{
            }}
              id="demo-simple-select-label">Season</InputLabel>
            <Select
              sx={{
                height: '6vh',
                marginBottom: '2vh',
                fontSize: '14px',
              }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={season}
              label="start date"
              onChange={handleseasonchange}
            >
               <MenuItem value={"Long Rain"}>Long Rain (March)</MenuItem>
              <MenuItem value={"Short Rain"}>Short Rain (November)</MenuItem>
              <MenuItem value={"Custom"}>Custom</MenuItem>
            </Select>
          </FormControl>
          {season === "Custom" && (
      <div style={{ marginRight: '10px', color: 'black', marginBottom: '10px' }}>
        <TextField
         sx={{
          width: '102%', // Ensure the width matches the Select component
        }}
          type="date"
          label="Select Date"
          value={productionDateSelected}
          onChange={(e) => {
            dispatch(changeproductionDate(e.target.value));
          }}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            min: "2024-01-01",
          }}
          fullWidth
          size="small"
        />
      </div>
      )}
      <div className="dataset-heading" style={{ color: '#000', fontSize: '13px', fontWeight: 500, lineHeight: '3vh', letterSpacing: '0.46px', textTransform: 'uppercase', marginBottom: '0.7vh', marginTop: '15px' }}>
        ACTIVITY
      </div>
      <FormControl fullWidth>
        <InputLabel id="activity-select-label">Select Activities</InputLabel>
        <Select
          labelId="activity-select-label"
          id="activity-select"
          multiple
          value={props.selectedActivities}
          onChange={(event) => props.setSelectedActivities(event.target.value)}
          renderValue={(selected) => selected.join(', ')}
          style={{ height: '6vh', fontSize: '14px', alignItems: 'center', marginBottom: '2vh' }}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: '300px', // adjust this value as needed
                // this ensures the menu appears right below the select input
              }
            }
          }}
        >
          {activityOptions.map((activity) => (
            <MenuItem key={activity.value} value={activity.value}>
              <Checkbox checked={props.selectedActivities.indexOf(activity.value) > -1} />
              <ListItemText primary={activity.label} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div className="custom-button" style={{ width: '50%', backgroundColor: '#00253D' }}
          onClick={handleSubmit}
        // disabled={submitDisabled}
        >
          Submit
        </div>
        <div className="custom-button" style={{ width: '50%', display: 'flex', flexDirection: 'row' }} onClick={handleClearMap}>
          Clear Map Selection
        </div>
      </div>
    </div>
  );
};

export default AdvisoryTable;
